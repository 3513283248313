import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "./LoginForm.css";
import { FaUser } from "react-icons/fa";
import { IoDocumentLockOutline } from "react-icons/io5";
import login from "./../Api/Login";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [attempts, setAttempts] = useState(0); // Track failed attempts
  const [lockout, setLockout] = useState(false); // Lockout state
  const navigate = useNavigate();
  const maxAttempts = 3; // Maximum allowed attempts

  useEffect(() => {
    const rememberedUsername = localStorage.getItem("rememberedUsername");
    if (rememberedUsername) {
      setUsername(rememberedUsername);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (lockout) {
      setError("Too many failed attempts. Please try again later.");
      return;
    }

    try {
      const response = await login(username, password);
      if (response && response.Customer) {
        const { id, NombreUsuario, token, OS } = response.Customer;
        localStorage.setItem("rsToken", token);

        if (rememberMe) {
          localStorage.setItem("rememberedUsername", username);
        } else {
          localStorage.removeItem("rememberedUsername");
        }
        navigate("/Home", { state: { id, NombreUsuario, OS } });
      } else {
        setAttempts(attempts + 1);
        if (attempts + 1 >= maxAttempts) {
          setLockout(true); // Lock the user out after too many attempts
          setError("Too many failed attempts. Please try again later.");
        } else {
          setError("Login failed. Please check your credentials.");
        }
      }
    } catch (error) {
      setAttempts(attempts + 1); // Increment the failed attempts
      if (attempts + 1 >= maxAttempts) {
        setLockout(true); // Lock the user out after too many attempts
        setError("Too many failed attempts. Please try again later.");
      } else {
        setError("Login failed. Please check your credentials.");
      }
    }
  };

  return (
    <div className="login-background">
      <div className="wrapper">
        <form action="" onSubmit={handleSubmit}>
          <h1>Inicio de sesión</h1>
          <div className="input-box">
            <input
              type="text"
              placeholder="RUC"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <FaUser className="icon" />
          </div>
          <div className="input-box">
            <input
              type="text"
              placeholder="Orden de Servicio"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <IoDocumentLockOutline className="icon" />
          </div>
          {error && <p className="error-message">{error}</p>}{" "}
          {/* Display error messages */}
          <div className="remember-forgot">
            <label>
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              Recuérdame
            </label>
            {/*<a href="#">¿Olvidé mi número de Orden?</a>*/}
          </div>
          <button type="submit" disabled={lockout}>
            Iniciar Sesión
          </button>{" "}
          {/* Disable button if locked out */}
          <div className="register-link">
            <p>
              No eres cliente aún?{" "}
              <a
                href="https://wa.me/51956558211?text=Estoy%20interesado%20en%20ser%20su%20cliente&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                Comunícate con nosotros
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
