import React from "react";
import { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
//import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useOSContext } from "../../OSContext";

const Item = ({ title, to, icon, selected, setSelected, state }) => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleClick = () => {
    setSelected(title);
    localStorage.setItem("selectedTab", title);
    navigate(to, { state });
  };

  return (
    <MenuItem active={selected === title} icon={icon} onClick={handleClick}>
      <Typography variant="h6">{title}</Typography>
    </MenuItem>
  );
};

const SidebarMenu = ({ isCollapsed, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { Id, Usuario } = useOSContext();
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve the selected tab from localStorage on page load
  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab) {
      setSelected(savedTab);
    }
  }, []);

  // Automatically update selected state based on the current URL
  useEffect(() => {
    if (location.pathname.includes("/home")) {
      setSelected("Ver Orden de Servicio");
    } else if (location.pathname.includes("/home/Faq")) {
      setSelected("Preguntas Frecuentes");
    }
  }, [location]);

  const logout = () => {
    localStorage.removeItem("rsToken");
    localStorage.removeItem("selectedTab"); // Clear selected tab on logout
    navigate("/");
  };

  return (
    <Box height="95vh" display="flex">
      <Sidebar
        collapsed={isCollapsed}
        transitionDuration={500}
        backgroundColor="transparent"
        rootStyles={{
          color: colors.grey[100],
          [`.${sidebarClasses.container}`]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        <Menu
          iconShape="square"
          menuItemStyles={{
            button: {
              backgroundColor: "transparent",
              "&:hover": {
                color: "#868dfb !important",
                backgroundColor: colors.grey[800],
              },
              "&.active": { color: "#6870fa !important" },
            },
            label: ({ open }) => ({
              fontWeight: open ? 600 : undefined,
            }),
          }}
        >
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            rootStyles={{
              margin: "10px 0 20px 0",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3">PANEL</Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          <Box display={"flex"} justifyContent="center">
            {!isCollapsed && (
              <img
                className="bordered-img"
                src="https://reencauchadoraelsol.com/wp-content/uploads/2020/04/cropped-logo-grande-1-1.jpg"
                width={160}
                height={70}
                alt="Reencauchadora El Sol"
                border="2px solid "
              />
            )}
            {isCollapsed && (
              <img
                className="bordered-img"
                src="https://reencauchadoraelsol.com/wp-content/uploads/2020/03/cropped-logo-peque%C3%B1o-180x180.jpg"
                width={70}
                height={70}
                alt="Reencauchadora El Sol"
              />
            )}
          </Box>
          {!isCollapsed && (
            <Box
              mt="10%"
              ml="15%"
              display="flexbox"
              justifyContent="space-evenly"
              alignItems="center"
              width="190px"
            >
              <Box display="flex" justifyContent={"center"}>
                <Typography variant="h5" color={colors.grey[100]}>
                  ¡Bienvenido!
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                m="5px"
              >
                <Typography variant="h5" color={colors.bluAccent[400]}>
                  {Usuario}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography color={colors.greenAccent[300]}>
                  Id: {Id}
                </Typography>
              </Box>
            </Box>
          )}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Ver Orden de Servicio"
              to="/home/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              state={{ Id, Usuario }}
            />
            <Item
              title="Preguntas Frecuentes"
              to="/home/Faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              state={{ Id, Usuario }}
            />
          </Box>
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <MenuItem onClick={logout} icon={<LogoutOutlinedIcon />}>
              <Typography variant="h6">Cerrar Sesión</Typography>
            </MenuItem>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default SidebarMenu;
